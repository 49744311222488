export const serviceArea = [
     {
        name: "Bendigo",
        Suburb: "Bendigo",
        hide: false,
      },
     {
        name: "Bendigo city centre",
        Suburb: "Bendigo-city-centre",
        hide: true,
      },
     {
        name: "Ironbark",
        Suburb: "Ironbark",
        hide: true,
      },
     {
        name: "Quarry Hill",
        Suburb: "Quarry-Hill",
        hide: true,
      },
     {
        name: "North Bendigo",
        Suburb: "North-Bendigo",
        hide: true,
      },
     {
        name: "Kennington",
        Suburb: "Kennington",
        hide: true,
      },
     {
        name: "Long Gully",
        Suburb: "Long-Gully",
        hide: true,
      },
     {
        name: "Golden Square",
        Suburb: "Golden-Square",
        hide: true,
      },
     {
        name: "Flora Hill",
        Suburb: "Flora-Hill",
        hide: true,
      },
     {
        name: "Strathdale",
        Suburb: "Strathdale",
        hide: true,
      },
     {
        name: "California Gully",
        Suburb: "California-Gully",
        hide: true,
      },
     {
        name: "East Bendigo",
        Suburb: "East-Bendigo",
        hide: true,
      },
     {
        name: "White Hills",
        Suburb: "White-Hills",
        hide: true,
      },
     {
        name: "Jackass Flat",
        Suburb: "Jackass-Flat",
        hide: true,
      },
     {
        name: "Kangaroo Flat",
        Suburb: "Kangaroo-Flat",
        hide: false,
      },
     {
        name: "Eaglehawk",
        Suburb: "Eaglehawk",
        hide: true,
      },
     {
        name: "Epsom",
        Suburb: "Epsom",
        hide: true,
      },
     {
        name: "Maiden Gully",
        Suburb: "Maiden-Gully",
        hide: true,
      },
     {
        name: "Mandurang",
        Suburb: "Mandurang",
        hide: true,
      },
     {
        name: "Sailors Gully",
        Suburb: "Sailors-Gully",
        hide: true,
      },
     {
        name: "Junortoun",
        Suburb: "Junortoun",
        hide: false,
      },
     {
        name: "Ascot",
        Suburb: "Ascot",
        hide: true,
      },
     {
        name: "Strathfieldsaye",
        Suburb: "Strathfieldsaye",
        hide: false,
      },
     {
        name: "Huntly",
        Suburb: "Huntly",
        hide: true,
      },
     {
        name: "Lockwood",
        Suburb: "Lockwood",
        hide: true,
      },
     {
        name: "Marong",
        Suburb: "Marong",
        hide: false,
      },
     {
        name: "Sedgwick",
        Suburb: "Sedgwick",
        hide: false,
      },
     {
        name: "Lockwood South",
        Suburb: "Lockwood-South",
        hide: true,
      },
     {
        name: "Eppalock",
        Suburb: "Eppalock",
        hide: true,
      },
];
